import { Component } from '@angular/core';

@Component({
  selector: 'app-cookie-notice',
  standalone: true,
  imports: [],
  templateUrl: './cookie-notice.component.html',
  styleUrl: './cookie-notice.component.scss',
})
export class CookieNoticeComponent {}
