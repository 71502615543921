import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { ThemeService } from '../../services/theme/theme.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  imports: [
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSlideToggleModule,
    RouterModule,
  ],
})
export class AppMenuComponent {
  private _themeService = inject(ThemeService);
  public theme$ = this._themeService.theme$;

  constructor() {}

  public isDarkMode(): boolean {
    let currentTheme = '';
    this.theme$.subscribe((mode) => {
      currentTheme = mode;
    });
    return currentTheme === 'dark';
  }

  public toggleTheme(): void {
    this._themeService.toggleDarkMode();
  }
}
