<button mat-menu-item>
  <mat-slide-toggle
    class="flex justify-center"
    [checked]="isDarkMode()"
    (change)="toggleTheme()"
  >
    <div class="text-slate-900 dark:text-white">Dark Mode</div>
  </mat-slide-toggle>
</button>
<button mat-menu-item [routerLink]="['/']">
  <mat-icon>home</mat-icon>Home
</button>
<button mat-menu-item routerLink="/recent-changes">
  <mat-icon>new_releases</mat-icon>
  What's New
</button>
<button mat-menu-item routerLink="/pricing">
  <mat-icon>price_check</mat-icon>
  GFX Pricing
</button>
<button mat-menu-item routerLink="/about">
  <mat-icon>question_mark</mat-icon>
  About
</button>
