<div class="max-w-5xl mx-auto min-h-full p-2">
  <h1>COOKIE NOTICE</h1>
  <p>Last updated August 9th, 2024</p>
  <p>
    This Cookie Notice explains how Primary Key Software LLC ("Company," "we,"
    "us," and "our") uses cookies and similar technologies to recognize you when
    you visit our website at https://groupfitx.com ("Website"). It explains what
    these technologies are and why we use them, as well as your rights to
    control our use of them.
  </p>
  <p>
    In some cases we may use cookies to collect personal information, or that
    becomes personal information if we combine it with other information.
  </p>
  <h2>What are cookies?</h2>
  <p>
    Cookies are small data files that are placed on your computer or mobile
    device when you visit a website. Cookies are widely used by website owners
    in order to make their websites work, or to work more efficiently, as well
    as to provide reporting information.
  </p>
  <p>
    Cookies set by the website owner (in this case, Primary Key Software LLC)
    are called "first-party cookies." Cookies set by parties other than the
    website owner are called "third-party cookies." Third-party cookies enable
    third-party features or functionality to be provided on or through the
    website (e.g., advertising, interactive content, and analytics). The parties
    that set these third-party cookies can recognize your computer both when it
    visits the website in question and also when it visits certain other
    websites.
  </p>
  <div class="grid gap-y-4">
    <section>
      <h2>Why do we use cookies?</h2>
      <p>
        We use first- and third-party cookies for several reasons. Some cookies
        are required for technical reasons in order for our Website to operate,
        and we refer to these as "essential" or "strictly necessary" cookies.
        Other cookies also enable us to track and target the interests of our
        users to enhance the experience on our Online Properties. Third parties
        serve cookies through our Website for advertising, analytics, and other
        purposes. This is described in more detail below.
      </p>
      <h2>How can I control cookies?</h2>
      <p>
        You have the right to decide whether to accept or reject cookies. You
        can exercise your cookie rights by setting your preferences in the
        Cookie Preferences dialog.
      </p>
      <p>
        The Cookie Consent Manager can be found in the notification banner and
        on our website. If you choose to reject cookies, you may still use our
        website though your access to some functionality and areas of our
        website may be restricted. You may also set or amend your web browser
        controls to accept or refuse cookies.
      </p>
    </section>
    <section>
      <h2>How can I control cookies on my browser?</h2>
      <p>
        Many advertising networks allow you to opt out of targeted advertising:
      </p>

      <ul class="list-decimal list-inside">
        <li>
          <a href="https://optout.aboutads.info/"
            >Digital Advertising Alliance</a
          >
        </li>
        <li>
          <a href="https://thenai.org/opt-out/" target="_blank"
            >Network Advertising Initiative</a
          >
        </li>
        <li>
          <a href="http://www.youronlinechoices.com/" target="_blank"
            >European Interactive Digital Advertising Alliance</a
          >
        </li>
      </ul>
    </section>
    <section>
      <h2>More questions? Contact us below:</h2>

      <address>
        <p>
          privacy&#64;groupfitx.com<br />
          Primary Key Software LLC<br />
          Round Rock, TX 78664<br />
          United States<br />
        </p>
      </address>
    </section>
  </div>
</div>
