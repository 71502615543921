import { afterNextRender, Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { CanonicalLinkService } from './services/canonical-link.service';
import { filter } from 'rxjs';

declare const gtag: Function; // <------------Important: the declartion for gtag is required!

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HeaderComponent, RouterOutlet, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(
    private meta: Meta,
    private router: Router,
    canonicalLinkService: CanonicalLinkService
  ) {
    if (environment.hideFromRobots) {
      this.meta.updateTag({ name: 'robots', content: 'noindex' });
    }

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        canonicalLinkService.setCanonicalUrl();
      });

    afterNextRender(() => {
      this.setUpAnalytics();
    });
  }

  setUpAnalytics() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((incomingEvent: any) => {
        const event = incomingEvent as NavigationEnd;
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
      });
  }
}
