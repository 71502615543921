<div
  class="flex flex-col h-[100dvh] bg-cover bg-center bg-gradient-radial from-white to-zinc-200 dark:from-gfx-cardbg dark:to-gfx-bg-end-gradient"
>
  <div class="">
    <app-header></app-header>
  </div>
  <div class="flex-grow overflow-auto">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
