<div
  class="w-full h-14 bg-gfx-primary text-white p-1 flex items-center justify-center"
>
  <div
    class="flex h-14 bg-gfx-primary text-white p-1 items-center justify-center"
  >
    <span class="flex flex-row items-center">
      <a class="text-xs hover:underline hover:cursor-pointer" href="/terms"
        >Terms</a
      >
      <span class="mx-1">|</span>
      <a class="text-xs hover:underline hover:cursor-pointer" href="/privacy"
        >Privacy</a
      >
    </span>
  </div>
</div>
