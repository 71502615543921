<div class="mx-auto max-w-5xl min-h-full p-2">
  <h1>PRIVACY POLICY</h1>

  <p>Last updated August 9th, 2024</p>
  <div class="grid gap-y-4">
    <section>
      <p>
        This privacy notice for Primary Key Software LLC ("<strong>we</strong>,"
        "<strong>us</strong>," or "<strong>our</strong>"), describes how and why
        we might collect, store, use, and/or share ("<strong>process</strong>")
        your information when you use our services
        ("<strong>Services</strong>"), such as when you:
      </p>
      <div class="my-2">
        <ul class="list-decimal list-inside">
          <li class="ml-2">
            Visit our website at
            <a href="https://groupfitx.com" target="_blank"
              >https://groupfitx.com</a
            >, or any website of ours that links to this privacy notice
          </li>
          <li class="ml-2">
            Engage with us in other related ways, including any sales,
            marketing, or events
          </li>
        </ul>
      </div>
      <p>
        <strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice
        will help you understand your privacy rights and choices. If you do not
        agree with our policies and practices, please do not use our Services.
        If you still have any questions or concerns, please contact us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >.
      </p>
    </section>

    <section>
      <h2>SUMMARY</h2>

      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our
        <a href="privacy#toc">table of contents</a>
        below to find the section you are looking for.
      </p>

      <ul class="list-decimal list-inside">
        <li class="ml-2">
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use.
          <a href="privacy#what-do-we-collect"
            >Learn more about personal information you disclose to us.</a
          >
        </li>

        <li class="ml-2">
          <strong>Do we process any sensitive personal information?</strong> We
          do not process sensitive personal information.
        </li>

        <li class="ml-2">
          <strong>Do we receive any information from third parties?</strong> We
          do not receive any information from third parties.
        </li>

        <li class="ml-2">
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so.
          <a href="privacy#how-do-we-process"
            >Learn more about how we process your information.</a
          >
        </li>

        <li class="ml-2">
          <strong
            >In what situations and with which parties do we share personal
            information?</strong
          >
          We may share information in specific situations and with specific
          third parties.
          <a href="privacy#share-personal-info"
            >Learn more about when and with whom we share your personal
            information.</a
          >
        </li>

        <li class="ml-2">
          <strong>How do we keep your information safe?</strong> We have
          organizational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information.
          <a href="privacy#keep-info-safe"
            >Learn more about how we keep your information safe.</a
          >
        </li>

        <li class="ml-2">
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information.
          <a href="privacy#your-privacy-rights"
            >Learn more about your privacy rights.</a
          >
        </li>

        <li class="ml-2">
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by contacting us. We will consider and act
          upon any request in accordance with applicable data protection laws.
        </li>
      </ul>
    </section>

    <section id="toc">
      <h2>TABLE OF CONTENTS</h2>

      <ul class="list-decimal list-inside">
        <li>
          <a href="privacy#what-do-we-collect"
            >WHAT INFORMATION DO WE COLLECT?</a
          >
        </li>
        <li>
          <a href="privacy#how-do-we-process"
            >HOW DO WE PROCESS YOUR INFORMATION?</a
          >
        </li>
        <li>
          <a href="privacy#share-personal-info"
            >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
          >
        </li>
        <li>
          <a href="privacy#do-we-use-cookies"
            >DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a
          >
        </li>
        <li>
          <a href="privacy#social-logins"
            >HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a
          >
        </li>
        <li>
          <a href="privacy#how-long-info"
            >HOW LONG DO WE KEEP YOUR INFORMATION?</a
          >
        </li>
        <li>
          <a href="privacy#keep-info-safe"
            >HOW DO WE KEEP YOUR INFORMATION SAFE?</a
          >
        </li>
        <li>
          <a href="privacy#information-from-minors"
            >DO WE COLLECT INFORMATION FROM MINORS?</a
          >
        </li>
        <li>
          <a href="privacy#your-privacy-rights"
            >WHAT ARE YOUR PRIVACY RIGHTS?</a
          >
        </li>
        <li>
          <a href="privacy#do-not-track">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </li>
        <li>
          <a href="privacy#us-residents"
            >DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a
          >
        </li>
        <li>
          <a href="privacy#do-we-make-updates"
            >DO WE MAKE UPDATES TO THIS NOTICE?</a
          >
        </li>
        <li>
          <a href="privacy#how-contact-us"
            >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
          >
        </li>
        <li>
          <a href="privacy#review-your-data">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?</a
          >
        </li>
      </ul>
    </section>

    <section id="what-do-we-collect">
      <h2>WHAT INFORMATION DO WE COLLECT?</h2>

      <h3>Personal information you disclose to us</h3>

      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>
      <h4>Personal Information Provided by You</h4>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the
        products and features you use. The personal information we collect may
        include the following: email addresses passwords
      </p>
      <h4>Sensitive Information</h4>
      <p>We do not process sensitive information.</p>
      <h4>Payment Data</h4>
      <p>
        We may collect data necessary to process your payment if you make
        purchases, such as your payment instrument number, and the security code
        associated with your payment instrument. All payment data is stored by
        Chargebee. You may view their privacy notice here:
        https://www.chargebee.com/privacy/
      </p>
      <h4>Social Media Login Data</h4>
      <p>
        We may provide you with the option to register with us using your
        existing social media account details, like your Facebook, X (formerly
        Twitter), or other social media account. If you choose to register in
        this way, we will collect the information described in the section
        <a href="privacy#social-logins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
        below.
      </p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <h3>Information automatically collected</h3>

      <p>
        Some information — such as your Internet Protocol (IP) address and/or
        browser and device characteristics — is collected automatically when you
        visit our Services.
      </p>

      <p>
        We automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes. Like many
        businesses, we also collect information through cookies and similar
        technologies.
      </p>
      <p>The information we collect includes:</p>
      <ul class="list-decimal list-inside">
        <li class="ml-2">
          <span class="italic">Log and Usage Data</span>. Log and usage data is
          service-related, diagnostic, usage, and performance information our
          servers automatically collect when you access or use our Services and
          which we record in log files. Depending on how you interact with us,
          this log data may include your IP address, device information, browser
          type, and settings and information about your activity in the Services
          (such as the date/time stamps associated with your usage, pages and
          files viewed, searches, and other actions you take such as which
          features you use), device event information (such as system activity,
          error reports (sometimes called "crash dumps"), and hardware
          settings).
        </li>
        <li class="ml-2">
          <span class="italic">Device Data.</span> We collect device data such
          as information about your computer, phone, tablet, or other device you
          use to access the Services. Depending on the device used, this device
          data may include information such as your IP address (or proxy
          server), device and application identification numbers, location,
          browser type, hardware model, Internet service provider and/or mobile
          carrier, operating system, and system configuration information.
        </li>
        <li class="ml-2">
          <span class="italic">Location Data.</span> We collect location data
          such as information about your device's location, which can be either
          precise or imprecise. How much information we collect depends on the
          type and settings of the device you use to access the Services. For
          example, we may use GPS and other technologies to collect geolocation
          data that tells us your current location (based on your IP address).
          You can opt out of allowing us to collect this information either by
          refusing access to the information or by disabling your Location
          setting on your device. However, if you choose to opt out, you may not
          be able to use certain aspects of the Services.
        </li>
      </ul>
    </section>

    <section id="how-do-we-process">
      <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>
        We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and
        to comply with law. We may also process your information for other
        purposes with your consent.
      </p>
      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including:
      </p>
      <div class="my-2">
        <ul class="list-decimal list-inside">
          <li class="ml-2">
            <strong
              >To facilitate account creation and authentication and otherwise
              manage user accounts.</strong
            >
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li class="ml-2">
            <strong
              >To deliver and facilitate delivery of services to the
              user.</strong
            >
            We may process your information to provide you with the requested
            service.
          </li>
          <li class="ml-2">
            <strong
              >To send you marketing and promotional communications.</strong
            >
            We may process the personal information you send to us for our
            marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time.
            For more information, see
            <a href="privacy#your-privacy-rights"
              >WHAT ARE YOUR PRIVACY RIGHTS?</a
            >
            below.
          </li>
          <li class="ml-2">
            <strong>To protect our Services.</strong> We may process your
            information as part of our efforts to keep our Services safe and
            secure, including fraud monitoring and prevention.
          </li>
          <li class="ml-2">
            <strong
              >To evaluate and improve our Services, products, marketing, and
              your experience</strong
            >
            We may process your information when we believe it is necessary to
            identify usage trends, determine the effectiveness of our
            promotional campaigns, and to evaluate and improve our Services,
            products, marketing, and your experience.
          </li>
          <li class="ml-2">
            <strong>To identify usage trends.</strong> We may process
            information about how you use our Services to better understand how
            they are being used so we can improve them.
          </li>
        </ul>
      </div>
      <h3>Limited Usage of Google Data</h3>
      <p>
        Group Fitness X's use and transfer of information received from Google
        APIs to any other app will adhere to Google API Services User Data
        Policy, including the Limited Use requirements.
      </p>
    </section>

    <section id="share-personal-info">
      <h2>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

      <p>
        We may share information in specific situations described in this
        section and/or with the following third parties. We may need to share
        your personal information in the following situations:
      </p>
      <ul class="list-decimal list-inside">
        <li>
          <span class="italic">Business Transfers.</span> We may share or
          transfer your information in connection with, or during negotiations
          of, any merger, sale of company assets, financing, or acquisition of
          all or a portion of our business to another company.
        </li>
        <li>
          <span class="italic">When we use Google Analytics.</span> We may share
          your information with Google Analytics to track and analyze the use of
          the Services. To opt out of being tracked by Google Analytics across
          the Services, visit
          <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
            >https://tools.google.com/dlpage/gaoptout.</a
          >
          For more information on the privacy practices of Google, please visit
          the Google Privacy & Terms page.
        </li>
      </ul>
    </section>

    <section id="do-we-use-cookies">
      <h2>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
      <p>
        We may use cookies and other tracking technologies to collect and store
        your information. We may use cookies and similar tracking technologies
        (like web beacons and pixels) to access or store information. Specific
        information about how we use such technologies and how you can refuse
        certain cookies is set out in our
        <a href routerLink="/cookies">Cookie Notice</a>.
      </p>
    </section>

    <section id="social-logins">
      <h2>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

      <p>
        If you choose to register or log in to our Services using a social media
        account, we may have access to certain information about you.
      </p>

      <p>
        Our Services offer you the ability to register and log in using your
        third-party social media account details (like your Facebook or Twitter
        logins). Where you choose to do this, we will receive certain profile
        information about you from your social media provider. The profile
        information we receive may vary depending on the social media provider
        concerned, but will often include your name, email address, friends
        list, and profile picture, as well as other information you choose to
        make public on such a social media platform.
      </p>

      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        on the relevant Services. Please note that we do not control, and are
        not responsible for, other uses of your personal information by your
        third-party social media provider. We recommend that you review their
        privacy notice to understand how they collect, use, and share your
        personal information, and how you can set your privacy preferences on
        their sites and apps.
      </p>
    </section>

    <section id="how-long-info">
      <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p>
        We keep your information for as long as necessary to fulfill the
        purposes outlined in this privacy notice unless otherwise required by
        law.
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>
    </section>

    <section id="keep-info-safe">
      <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <p>
        We aim to protect your personal information through a system of
        organizational and technical security measures.
      </p>
      <p>
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>
    </section>

    <section id="information-from-minors">
      <h2>8. DO WE COLLECT INFORMATION FROM MINORS?</h2>

      <p>
        We do not knowingly collect data from or market to children under 18
        years of age.
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using the Services, you represent that you are at least
        18 or that you are the parent or guardian of such a minor and consent to
        such minor dependent's use of the Services. If we learn that personal
        information from users less than 18 years of age has been collected, we
        will deactivate the account and take reasonable measures to promptly
        delete such data from our records. If you become aware of any data we
        have collected from children under age 18, please contact us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >.
      </p>
    </section>

    <section id="your-privacy-rights">
      <h2>9. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>You may review, change, or terminate your account at any time.</p>

      <p>
        <strong class="underline">Withdrawing your consent:</strong> If we are
        relying on your consent to process your personal information, which may
        be express and/or implied consent depending on the applicable law, you
        have the right to withdraw your consent at any time. You can withdraw
        your consent at any time by contacting us by using the contact details
        provided in the section
        <a href="privacy#how-contact-us"
          >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
        >
        below.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>
      <p>
        <strong class="underline"
          >Opting out of marketing and promotional communications:</strong
        >
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section
        <a href="privacy#how-contact-us"
          >HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a
        >
        below. You will then be removed from the marketing lists. However, we
        may still communicate with you — for example, to send you
        service-related messages that are necessary for the administration and
        use of your account, to respond to service requests, or for other
        non-marketing purposes.
      </p>

      <h3>Account Information</h3>

      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>
      <div class="my-2">
        <ul class="list-decimal list-inside">
          <li class="ml-2">
            Log in to your account settings and update your user account.
          </li>
          <li class="ml-2">
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </li>
        </ul>
      </div>

      <strong class="underline">Cookies and similar technologies</strong>: Most
      Web browsers are set to accept cookies by default. If you prefer, you can
      usually choose to set your browser to remove cookies and to reject
      cookies. If you choose to remove cookies or reject cookies, this could
      affect certain features or services of our Services. If you have questions
      or comments about your privacy rights, you may email us at
      <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
      >.
    </section>

    <section id="do-not-track">
      <h2>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized . As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>
    </section>

    <section id="us-residents">
      <h2>11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
      <p>
        If you are a resident of California, Colorado, Connecticut, Utah or
        Virginia, you are granted specific rights regarding access to your
        personal information.
      </p>
      <p>
        <strong>What categories of personal information do we collect?</strong>
      </p>
      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>

      <table class="w-full border border-solid">
        <thead>
          <tr>
            <th class="border border-solid p-1">Category</th>
            <th class="border border-solid p-1">Examples</th>
            <th class="border border-solid p-1">Collected</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="border border-solid p-1">A. Identifiers</td>
            <td class="border border-solid p-1">
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </td>
            <td class="border border-solid text-center p-1">YES</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              B. Personal information as defined in the California Customer
              Records statute
            </td>
            <td class="border border-solid p-1">
              Name, contact information, education, employment, employment
              history, and financial information
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              C. Protected classification characteristics under state or federal
              law
            </td>
            <td class="border border-solid p-1">Gender and date of birth</td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">D. Commercial information</td>
            <td class="border border-solid p-1">
              Transaction information, purchase history, financial details, and
              payment information
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">E. Biometric information</td>
            <td class="border border-solid p-1">
              Fingerprints and voiceprints
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              F. Internet or other similar network activity
            </td>
            <td class="border border-solid p-1">
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems, and advertisements
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">G. Geolocation data</td>
            <td class="border border-solid p-1">Device location</td>
            <td class="border border-solid text-center p-1">YES</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </td>
            <td class="border border-solid p-1">
              Images and audio, video or call recordings created in connection
              with our business activities
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              I. Professional or employment-related information
            </td>
            <td class="border border-solid p-1">
              Business contact details in order to provide you our Services at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">J. Education Information</td>
            <td class="border border-solid p-1">
              Student records and directory information
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              K. Inferences drawn from collected personal information
            </td>
            <td class="border border-solid p-1">
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
          <tr>
            <td class="border border-solid p-1">
              L. Sensitive personal Information
            </td>
            <td class="border border-solid p-1"></td>
            <td class="border border-solid text-center p-1">NO</td>
          </tr>
        </tbody>
      </table>

      <p>
        We will use and retain the collected personal information as needed to
        provide the Services or for:
      </p>
      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Category A - As long as the user has an account with us
        </li>
        <li class="ml-2">
          Category G - As long as the user has an account with us
        </li>
      </ul>

      <p>
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:
      </p>

      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Receiving help through our customer support channels
        </li>
        <li class="ml-2">Participation in customer surveys or contests</li>
        <li class="ml-2">
          Facilitation in the delivery of our Services and to respond to your
          inquiries
        </li>
      </ul>

      <h3>How do we use and share your personal information?</h3>
      <p>
        Learn about how we use your personal information in the section,
        <a href="privacy#how-do-we-process"
          >HOW DO WE PROCESS YOUR INFORMATION?</a
        >
      </p>
      <h3>Will your information be shared with anyone else?</h3>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Learn more about how we disclose personal information to in the section,
        <a href="privacy#share-personal-info"
          >WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a
        >
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be "selling" of your personal
        information.
      </p>
      <p>
        We have not disclosed, sold, or shared any personal information to third
        parties for a business or commercial purpose in the preceding twelve
        (12) months. We will not sell or share personal information in the
        future belonging to website visitors, users, and other consumers.
      </p>

      <h3>California Residents</h3>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law permits our users who are California residents to request and
        obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a
        registered account with the Services, you have the right to request
        removal of unwanted data that you publicly post on the Services. To
        request removal of such data, please contact us using the contact
        information provided below and include the email address associated with
        your account and a statement that you reside in California. We will make
        sure the data is not publicly displayed on the Services, but please be
        aware that the data may not be completely or comprehensively removed
        from all our systems (e.g. backups).
      </p>

      <h4>CCPA Privacy Notice</h4>

      <p>
        This section applies only to California residents. Under the California
        Consumer Privacy Act (CCPA), you have the rights listed below.
      </p>
      <p>The California Code of Regulations defines a "resident" as:</p>

      <ol class="list-decimal list-inside my-2">
        <li class="ml-2">
          every individual who is in the State of California for other than a
          temporary or transitory purpose and
        </li>
        <li class="ml-2">
          every individual who is domiciled in the State of California who is
          outside the State of California for a temporary or transitory purpose
        </li>
      </ol>

      <p>All other individuals are defined as "non-residents."</p>

      <p>
        If this definition of "resident" applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>

      <h5>Your rights with respect to your personal data</h5>
      <h6>Right to request deletion of the data — Request to delete</h6>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <h6>Right to be informed — Request to know</h6>

      <p>Depending on the circumstances, you have a right to know:</p>

      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Depending on the circumstances, you have a right to know:
        </li>
        <li class="ml-2">
          the categories of personal information that we collect
        </li>
        <li class="ml-2">
          the purposes for which the collected personal information is used
        </li>
        <li class="ml-2">
          whether we sell or share personal information to third parties
        </li>
        <li class="ml-2">
          the categories of personal information that we sold, shared, or
          disclosed for a business purpose
        </li>
        <li class="ml-2">
          the categories of third parties to whom the personal information was
          sold, shared, or disclosed for a business purpose
        </li>
        <li class="ml-2">
          the business or commercial purpose for collecting, selling, or sharing
          personal information
        </li>
        <li class="ml-2">
          the specific pieces of personal information we collected about you
        </li>
      </ul>

      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>

      <h6>
        Right to Non-Discrimination for the Exercise of a Consumer's Privacy
        Rights
      </h6>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>
      <h6>
        Right to Limit Use and Disclosure of Sensitive Personal Information
      </h6>
      <p>We do not process consumer's sensitive personal information.</p>

      <h6>Verification process</h6>

      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <h6>Other privacy rights</h6>

      <p>You may object to the processing of your personal information.</p>
      <p>
        You may request correction of your personal data if it is incorrect or
        no longer relevant, or ask to restrict the processing of the
        information.
      </p>
      <p>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf. We may deny a request from an authorized agent that does
        not submit proof that they have been validly authorized to act on your
        behalf in accordance with the CCPA.
      </p>
      <p>
        You may request to opt out from future selling or sharing of your
        personal information to third parties. Upon receiving an opt-out
        request, we will act upon the request as soon as feasibly possible, but
        no later than fifteen (15) days from the date of the request submission.
      </p>
      <p>
        To exercise these rights, you can contact us by email at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a>
        or by referring to the contact details at the bottom of this document.
        If you have a complaint about how we handle your data, we would like to
        hear from you.
      </p>

      <h3>Colorado Residents</h3>
      <p>
        This section applies only to Colorado residents. Under the Colorado
        Privacy Act (CPA), you have the rights listed below. However, these
        rights are not absolute, and in certain cases, we may decline your
        request as permitted by law.
      </p>
      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li class="ml-2">Right to access your personal data</li>
        <li class="ml-2">
          Right to correct inaccuracies in your personal data
        </li>
        <li class="ml-2">Right to request deletion of your personal data</li>
        <li class="ml-2">
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li class="ml-2">
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects ("profiling")
        </li>
      </ul>

      <p>
        To submit a request to exercisethese rights described above, please
        email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >.
      </p>
      <p>
        If we decline to take action regarding your request and you wish to
        appeal our decision, please email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >. Within forty-five (45) days of receipt of an appeal, we will inform
        you in writing of any action taken or not taken in response to the
        appeal, including a written explanation of the reasons for the
        decisions.
      </p>

      <h3>Connecticut Residents</h3>

      <p>
        This section applies only to Connecticut residents. Under the
        Connecticut Data Privacy Act (CTDPA), you have the rights listed below.
        However, these rights are not absolute, and in certain cases, we may
        decline your request as permitted by law.
      </p>
      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li class="ml-2">Right to access your personal data</li>
        <li class="ml-2">
          Right to correct inaccuracies in your personal data
        </li>
        <li class="ml-2">Right to request deletion of your personal data</li>
        <li class="ml-2">
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li class="ml-2">
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects ("profiling")
        </li>
      </ul>

      <p>
        To submit a request to exercise these rights described above, please
        email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >.
      </p>
      <p>
        If we decline to take action regarding your request and you wish to
        appeal our decision, please email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >. Within sixty (60) days of receipt of an appeal, we will inform you in
        writing of any action taken or not taken in response to the appeal,
        including a written explanation of the reasons for the decisions.
      </p>

      <h3>Utah Residents</h3>

      <p>
        This section applies only to Utah residents. Under the Utah Consumer
        Privacy Act (UCPA), you have the rights listed below. However, these
        rights are not absolute, and in certain cases, we may decline your
        request as permitted by law.
      </p>
      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li class="ml-2">Right to access your personal data</li>
        <li class="ml-2">Right to request deletion of your personal data</li>
        <li class="ml-2">
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li class="ml-2">
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising or the sale of personal data
        </li>
      </ul>

      <p>
        To submit a request to exercise these rights described above, please
        email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >.
      </p>

      <h3>Virginia Residents</h3>

      <p>Under the Virginia Consumer Data Protection Act (VCDPA):</p>
      <p>
        "Consumer" means a natural person who is a resident of the Commonwealth
        acting only in an individual or household context. It does not include a
        natural person acting in a commercial or employment context.
      </p>
      <p>
        "Personal data" means any information that is linked or reasonably
        linkable to an identified or identifiable natural person. "Personal
        data" does not include de-identified data or publicly available
        information.
      </p>
      <p>
        "Sale of personal data" means the exchange of personal data for monetary
        consideration.
      </p>
      <p>
        If this definition of "consumer" applies to you, we must adhere to
        certain rights and obligations regarding your personal data.
      </p>

      <p>Your rights with respect to your personal data:</p>
      <ul class="list-decimal list-inside my-2">
        <li class="ml-2">
          Right to be informed whether or not we are processing your personal
          data
        </li>
        <li class="ml-2">Right to access your personal data</li>
        <li class="ml-2">
          Right to correct inaccuracies in your personal data
        </li>
        <li class="ml-2">Right to request deletion of your personal data</li>
        <li class="ml-2">
          Right to obtain a copy of the personal data you previously shared with
          us
        </li>
        <li class="ml-2">
          Right to opt out of the processing of your personal data if it is used
          for targeted advertising, the sale of personal data, or profiling in
          furtherance of decisions that produce legal or similarly significant
          effects ("profiling")
        </li>
      </ul>

      <h4>Exercise your rights provided under the Virginia VCDPA</h4>

      <p>
        You may contact us by email at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >.
      </p>

      <p>
        If you are using an authorized agent to exercise your rights, we may
        deny a request if the authorized agent does not submit proof that they
        have been validly authorized to act on your behalf.
      </p>

      <h4>Verification process</h4>

      <p>
        We may request that you provide additional information reasonably
        necessary to verify you and your consumer's request. If you submit the
        request through an authorized agent, we may need to collect additional
        information to verify your identity before processing your request.
      </p>
      <p>
        Upon receiving your request, we will respond without undue delay, but in
        all cases, within forty-five (45) days of receipt. The response period
        may be extended once by forty-five (45) additional days when reasonably
        necessary. We will inform you of any such extension within the initial
        45-day response period, together with the reason for the extension.
      </p>
      <h4>Right to appeal</h4>

      <p>
        If we decline to take action regarding your request, we will inform you
        of our decision and reasoning behind it. If you wish to appeal our
        decision, please email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
        >. Within sixty (60) days of receipt of an appeal, we will inform you in
        writing of any action taken or not taken in response to the appeal,
        including a written explanation of the reasons for the decisions. If
        your appeal is denied, you may contact the
        <a
          href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
          target="_blank"
          >Attorney General</a
        >
        to submit a complaint.
      </p>
    </section>

    <section id="do-we-make-updates">
      <h2>12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        Yes, we will update this notice as necessary to stay compliant with
        relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>
    </section>

    <section id="how-contact-us">
      <h2>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

      <p>
        If you have questions or comments about this notice, you may contact us
        at:
      </p>
      <address>
        <p>
          <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a
          ><br />
          Primary Key Software LLC<br />
          Round Rock, TX 78664<br />
          United States<br />
        </p>
      </address>
    </section>

    <section id="review-your-dat">
      <h2>
        14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please email us at
        <a [href]="privacyEmailHref">{{ privacyEmailAddress }}</a>
      </p>
    </section>
  </div>
</div>
