<mat-toolbar class="bg-gfx-primary" role="navigation">
  <!-- Set the width to constant here and in the right-most span so that
  the menu bar text is centered in the page, as opposed to centered
  in the remaining gap between the left-most and right-most content -->
  <span class="w-20 flex items-center gap-2 mr-2">
    <button
      mat-icon-button
      [matMenuTriggerFor]="appMenu"
      matTooltip="GFX Menu"
      aria-label="GFX Menu"
    >
      <mat-icon class="text-white">menu</mat-icon>
    </button>
    <button
      mat-mini-fab
      [routerLink]="['/']"
      aria-label="Side navigation menu"
      matTooltip="GFX Home"
      class="bg-gfx-accent"
    >
      <img src="/assets/images/gfx-heartbeat.webp" alt="GFX Logo" />
    </button>
  </span>
  <span class="flex-auto text-center text-white"></span>
  <span class="flex items-center">
    <a [href]="getLoginLink()">
      <button mat-flat-button class="gfx-button-secondary text-slate-900">
        Sign In / Create Account
      </button>
    </a>
  </span>
</mat-toolbar>

<mat-menu #appMenu="matMenu"><app-menu></app-menu></mat-menu>
