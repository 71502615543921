import { afterNextRender, Component } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConsentManagerService } from '../../services/consent-manager.service';
import { openCookieConsentBottomSheet } from '../cookie-consent-sheet/cookie-consent-sheet.component';

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(
    consentManager: ConsentManagerService,
    bottomSheet: MatBottomSheet
  ) {
    afterNextRender(() => {
      if (!consentManager.hasConsent())
        openCookieConsentBottomSheet(bottomSheet);
    });
  }
}
