import { Injectable, Inject, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { APP_URLS, AppUrls } from '../models/api-urls';

@Injectable({
  providedIn: 'root',
})
export class CanonicalLinkService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(APP_URLS) private appUrls: AppUrls,
    rendererFactory: RendererFactory2,
    private router: Router
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Manually set the canonical url for special cases
  public setCanonicalUrl() {
    const link = this.renderer.createElement('link');

    link.setAttribute('rel', 'canonical');

    const existingLink =
      this.document.head.querySelectorAll('[rel="canonical"]')[0];

    existingLink
      ? this.document.head.replaceChild(link, existingLink)
      : this.renderer.appendChild(this.document.head, link);

    link.setAttribute('href', `${this.appUrls.hostedUrl}${this.router.url}`);
    console.log('canonical url was set');
  }
}
