import { InjectionToken } from '@angular/core';

export const APP_URLS = new InjectionToken('APP_URLS');

export interface AppUrls {
  /** The url for the main GFX app */
  gfxWebsiteUrl: string;
  /** The url where this app is hosted */
  hostedUrl: string;
}
