import { Component } from '@angular/core';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
})
export class TermsComponent {
  public legalEmailAddress = 'legal@groupfitx.com';
  public legalEmailHref = `mailto:${this.legalEmailAddress}`;
}
