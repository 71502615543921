import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { AppMenuComponent } from '../app-menu/app-menu.component';
import { APP_URLS, AppUrls } from '../../models/api-urls';

@Component({
  selector: 'app-header',
  standalone: true,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    AppMenuComponent,
    RouterModule,
  ],
})
export class HeaderComponent {
  public gfxWebsiteUrl: string;
  constructor(@Inject(APP_URLS) appUrls: AppUrls) {
    this.gfxWebsiteUrl = appUrls.gfxWebsiteUrl;
  }

  getLoginLink(): string {
    return `${this.gfxWebsiteUrl}/login`;
  }
}
