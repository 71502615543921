import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { GfxPricingComponent } from './components/pricing/pricing.component';
import { AboutComponent } from './components/about/about.component';
import { RecentChangesComponent } from './components/recent-changes/recent-changes.component';
import { CookieNoticeComponent } from './cookie-notice/cookie-notice.component';

export const routes: Routes = [
  {
    path: 'recent-changes',
    title: 'GFX Recent Changes',
    component: RecentChangesComponent,
    data: { canonical: '/recent-changes' },
  },
  { path: 'pricing', title: 'GFX Pricing', component: GfxPricingComponent },
  { path: 'terms', title: 'GFX Terms of Use', component: TermsComponent },
  {
    path: 'privacy',
    title: 'GFX Privacy Statement',
    component: PrivacyComponent,
  },
  {
    path: 'cookies',
    title: 'GFX Cookie Policy',
    component: CookieNoticeComponent,
  },
  {
    path: 'about',
    title: 'About Group Fitness X',
    component: AboutComponent,
    data: { canonical: '/about' },
  },

  {
    path: 'home',
    title: 'Group Fitness X Home',
    component: HomeComponent,
  },
  {
    path: '',
    title: 'Group Fitness X Home',
    component: HomeComponent,
  },
];
